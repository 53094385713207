export enum AppTrackerEvent 
{
}

export enum AppTrackerEventCategory
{
}

/** Represents an image on the web. */
export interface ImageInfo 
{
    url: string;
    width: number;
    height: number;
}

/** The GraphQL query field details for a web image. */
export let imageInfoGraphQlDetails = 'url width height';