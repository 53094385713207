import PortfolioCategory from '@business/portfolio/PortfolioCategory';
import config from '@config';
import { useAppStateSelector } from '@redux/hooks';
import ALink from '@xFrame4/components/next/ALink';
import SmoothImage from '@xFrame4/components/SmoothImage';
import { FunctionComponent, useState } from 'react';

interface PortfolioCategoryThumbnailProps
{
    portfolioCategory: PortfolioCategory;
}

const PortfolioCategoryThumbnail: FunctionComponent<PortfolioCategoryThumbnailProps> = (props) =>
{
    const language = useAppStateSelector(state => state.layout.language);
    const translation = props.portfolioCategory.getTranslation(language.code);
    const [isHover, setIsHover] = useState<boolean>(false);


    
    /** Render */
    return (
        <div 
            className="portfolio-category-thumbnail col-md-6 col-lg-4"
        >
            <div 
                className="portfolio-category-thumbnail-inner w-100 h-100 cursor-pointer"
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
            >
                <SmoothImage 
                    src={props.portfolioCategory.thumbnailImage.url}
                    width={props.portfolioCategory.thumbnailImage.width}
                    height={props.portfolioCategory.thumbnailImage.height}
                    createFreshImageUrl={true}
                    className="w-100 h-100 object-fit-cover"
                />
                <div className="portfolio-category-thumbnail-overlay" style={{ opacity: isHover ? 1 : 0 }}>
                    <ALink 
                        href={props.portfolioCategory.getUrl(language.code)}
                        className="w-100 h-100 d-flex flex-column justify-content-end pb-6 ps-4"
                    >
                        <div className="portfolio-category-thumbnail-title text-white fw-bold mb-d075">{translation?.title}</div>
                        <div className="portfolio-category-thumbnail-subtitle text-white">{translation?.subtitle}</div>
                    </ALink>
                </div>
            </div>
        </div>
    );
}

export default PortfolioCategoryThumbnail;