import { ApolloQueryResult, gql } from '@apollo/client';
import client from '@graphql/apollo-client';
import BusinessEntity, { BusinessEntityField, BusinessEntityFieldConstructorParams, EntityManager, EntityManagerParameters } from './BusinessEntity';

export class BusinessEntityTranslation
{
    [index: string]: any; //https://www.typescriptlang.org/docs/handbook/2/objects.html#index-signatures
    language!: string;
    
}

export class BusinessEntityWithTranslation<BT extends BusinessEntityTranslation> extends BusinessEntity
{
    /** The primary translation language. */
    primaryLanguage!: string;
    /** The translation objects for each translation language. */
    translations: BT[] = [];
    /** Initiate a translation object. Needs to be defined */
    protected createTranslationObject!: () => BT;

    /** Get the translation object for the primary translation language. */
    get primaryTranslation()
    {
        return this.getTranslation(this.primaryLanguage);
    }

    /**
     * Get the translation object for a language.
     * 
     * @param language The language code.
     */
    getTranslation(language: string)
    {
        return this.translations.find(et => et.language == language);
    }

    /**
     * Get the value of a translated field.
     * 
     * @param field The field name
     * @param language The translation language.
     * @returns The value of the translated field.
     */
    getField(field: string, language: string)
    {
        let translation = this.translations.find(et => et.language == language);
        if (translation !== undefined)
        {
            return translation[field];
        }
        else
        {
            return undefined;
        }
    }

    /**
     * Initiate an empty translation for the language. The translation is added to the translations collection.
     * 
     * @param language The language code.
     * @returns The new translation object or an existing one (if translation already exists for the language).
     */
    initTranslation(language: string)
    {
        let translation = this.translations.find(et => et.language == language);
        if (translation === undefined)
        {
            translation = this.createTranslationObject();
            translation.language = language;
            this.translations.push(translation);
        }

        return translation;
    }
    
    /**
     * Save the entity translation for a language. Saves the entity too.
     * 
     * @param language The language code.
     * @param translation The translation object.
     */
    async saveTranslation(language: string, translation: BusinessEntityTranslation)
    {
        // Save the entity first
        if (this.id == null && this.primaryLanguage == null) this.primaryLanguage = language;
        await this.save();

        // Save the translation
        const cls = this['constructor'] as typeof BusinessEntityWithTranslation;

        return await (cls.manager as EntityWithTranslationManager<BT, BusinessEntityWithTranslation<BT>>).saveTranslation(this, language, translation);
    }
}

export class EntityWithTranslationManager<BT extends BusinessEntityTranslation, B extends BusinessEntityWithTranslation<BT>> extends EntityManager<B>
{
    /** The translated fields. */
    translationFields: BusinessEntityField[] = [];
    /** The name of the save entity translation mutation in the schema. */
    graphQlSaveTranslationAlias?: string;
    /** The name of the translation InputObjectType in the schema. */
    graphQlTranslationInputAlias?: string;

    constructor(parameters: EntityWithTranslationManagerParameters<BT, B>)
    {
        super(parameters);

        // The translation fields (language always exists)
        this.translationFields = parameters.translationFields.map(constructorInput => new BusinessEntityField(constructorInput));
        this.translationFields.push(new BusinessEntityField({
            name: 'language',
            isRequiredInput: true
        })); 
        for (let translationField of this.translationFields)
        {
            if (translationField.relatedManager == 'self')
            {
                translationField.relatedManager = this;
            }
        }

        // Add primaryLanguage to fields list
        this.fields.push(new BusinessEntityField({ name: 'primaryLanguage', isRequiredInput: true }));

        // Add 'translations' to fields list
        this.fields.push(new BusinessEntityField({ 
            name: 'translations', 
            isInput: false, 
            customGraphQl: 'language ' +  this.translationFields.map(tf => tf.name).join(' ')
        }));

        // Other properties
        this.graphQlSaveTranslationAlias = parameters.graphQlSaveAlias ?? 'save' + this.name + 'Translation';
        this.graphQlTranslationInputAlias = parameters.graphQlTranslationInputAlias ?? this.name + 'TranslationInput';
    }

    async saveTranslation(entity: B, language: string, translation: BusinessEntityTranslation)
    {
        // Create GraphQL mutation and the variables
        let gqlSaveMutation = this.createGraphQlSaveTranslationMutation();

        let variables = {
            id: entity.id,
            input: this.createTranslationInputObject(language, translation)
        }

        // Execute mutation
        let success = false;
        try
        {
            let result = await client.mutate({
                mutation: gql(gqlSaveMutation),
                variables: variables
            });

            success = result.data[this.graphQlSaveTranslationAlias!].success as boolean;

            if (success)
            {
                // The entity is reloaded from the GraphQL result
                let graphQlObject = result.data[this.graphQlSaveTranslationAlias!].object;
                entity.copyFromGraphQL(graphQlObject);
            }
            else
            {
                console.log('Save entity translation mutation not successful: ' + this.graphQlSaveTranslationAlias);
                console.log(gqlSaveMutation);
                console.log(JSON.stringify(variables));
            }
        }
        catch (e)
        {
            console.log('Error: save entity translation mutation failed: ' + this.graphQlSaveTranslationAlias);
            console.log((e as Error).message);
            console.log(gqlSaveMutation);
            console.log(JSON.stringify(variables));
            throw 'Save entity translation mutation failed: ' + this.graphQlSaveTranslationAlias;
        }

        return success;
    }

    /**
     * Create the GraphQL save translation mutation for the BusinessEntityWithTranslation.
     * https://graphql.org/learn/queries/#mutations
     * 
     * @returns The GraphQL mutation string.
     */
    createGraphQlSaveTranslationMutation()
    {
        let saveMutation = '';
        saveMutation += `mutation Save${this.name}Translation($id: Int!, $input: ${this.graphQlTranslationInputAlias}!) {` + '\n';
        saveMutation += `${this.graphQlSaveTranslationAlias}(id: $id, input: $input) {` + '\n';
        saveMutation += 'success' + '\n';
        saveMutation += 'object {' + '\n';
        saveMutation += `...${this.name}DetailsFragment` + '\n';
        saveMutation += '}' + '\n';
        saveMutation += '}' + '\n';
        saveMutation += '}' + '\n';
        saveMutation += this.buildEntityDetailsFragment();

        return saveMutation;
    }

    /**
     * Create an input object for the GraphQL save translation mutation.
     * https://graphql.org/learn/schema/#input-types
     * 
     * @param language The translation language.
     * @param translation The object that holds the translation texts.
     * @returns The GraphQL input object.
     */
    createTranslationInputObject(language: string, translation: BusinessEntityTranslation)
    {
        let inputObject: { [index: string]: any } = {};

        inputObject.id = translation.id;

        for (let translationField of this.translationFields)
        {
            if (translationField.name == 'language') 
            {
                inputObject[translationField.name] = language; // language is always set
            }
            else if (translationField.isInput)
            {
                // Check required field
                if (translation[translationField.name] === undefined && translationField.isRequiredInput)
                {
                    throw new Error(`Required translation input field ${translationField.name} on ${this.name} has no value set.`);
                }

                // The input field name (the input field name can differ from the actual field name. Eg: region -> regionId)
                let inputFieldName = translationField.inputName ?? translationField.name;

                // The translation field value
                inputObject[inputFieldName] = translation[inputFieldName];
            }
        }

        return inputObject;
    }
}

export interface EntityWithTranslationManagerParameters<BT extends BusinessEntityTranslation, B extends BusinessEntityWithTranslation<BT>> extends EntityManagerParameters<B> 
{
    /** The translated fields. */
    translationFields: BusinessEntityFieldConstructorParams[];
    /** The name of the save entity translation mutation in the schema. */
    graphQlSaveTranslationAlias?: string;
    /** The name of the translation InputObjectType in the schema. */
    graphQlTranslationInputAlias?: string;
}

export default BusinessEntity