import PortfolioCategory from '@business/portfolio/PortfolioCategory';
import { FunctionComponent } from 'react';
import PortfolioCategoryThumbnail from './PortfolioCategoryThumbnail';

interface PortfolioCategoryListProps
{
    portfolioCategories: PortfolioCategory[];
}

const PortfolioCategoryList: FunctionComponent<PortfolioCategoryListProps> = (props) =>
{
    /** Thumbnails */
    let cmpThumbnails = props.portfolioCategories.map(portfolioCategory =>
        <PortfolioCategoryThumbnail
            key={portfolioCategory.id}
            portfolioCategory={portfolioCategory}
        />
    )

    /** Render */
    return (
        <div className="portfolio-category-list row justify-content-start">
            {cmpThumbnails}
        </div>
    );
}

export default PortfolioCategoryList;