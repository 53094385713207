import config from '@config';
import { PortfolioCategoryStatus } from '@graphql/generated';
import { BusinessEntityTranslation, BusinessEntityWithTranslation, EntityWithTranslationManager } from '@xFrame4/business/BusinessEntityWithTranslation';
import { ImageInfo, imageInfoGraphQlDetails } from '@xFrame4/business/Constants';

export class PortfolioCategoryTranslation extends BusinessEntityTranslation
{
    title!: string;
    subtitle: string = '';
    slug: string = '';
    seoTitle: string = '';
    seoDescription: string = '';
    seoKeywords: string = '';    
}

export class PortfolioCategory extends BusinessEntityWithTranslation<PortfolioCategoryTranslation>
{
    protected createTranslationObject = () => new PortfolioCategoryTranslation();
    status: PortfolioCategoryStatus = PortfolioCategoryStatus.Enabled;
    orderNo!: number;
    thumbnailImage!: ImageInfo;
    itemCount!: number;
    
    static manager: EntityWithTranslationManager<PortfolioCategoryTranslation, PortfolioCategory> = new EntityWithTranslationManager<PortfolioCategoryTranslation, PortfolioCategory>({
        name: 'PortfolioCategory',
        createEntity: () => new PortfolioCategory(),
        fields: [
            { name: 'status', isRequiredInput: true },
            { name: 'orderNo' },
            { name: 'thumbnailImage', inputProperty: 'thumbnailImageFile', customGraphQl: imageInfoGraphQlDetails },
            { name: 'itemCount', isInput: false}
        ],
        translationFields: [
            { name: 'title', isRequiredInput: true },
            { name: 'subtitle' },
            { name: 'slug' },
            { name: 'seoTitle' },
            { name: 'seoDescription' },
            { name: 'seoKeywords' },
        ],
        graphQlQueryAlias: 'portfolioCategories',
    });

    getUrl(language: string)
    {
        let translation = this.getTranslation(language);

        return config.appUrl + `${language}/portfolio/${translation?.slug}`;
    }
}

export default PortfolioCategory;